import "./Advantages.css";

const cards = [
    { Number: 1, mainText: "Matndan ovozga va ovozdan matnga", Text: "Yozma matnni tabiiy tovushli nutqqa aylantirish. Ko'p til va ovozlarni qo'llab-quvvatlash." },
    { Number: 2, mainText: "Matndan ovozga va ovozdan matnga", Text: "Yozma matnni tabiiy tovushli nutqqa aylantirish. Ko'p til va ovozlarni qo'llab-quvvatlash." },
    { Number: 3, mainText: "Matndan ovozga va ovozdan matnga", Text: "Yozma matnni tabiiy tovushli nutqqa aylantirish. Ko'p til va ovozlarni qo'llab-quvvatlash." }
];

function Advantages() {
    return (
        <div className="advantage">
            <div className="top">
                <h1>Asosiy ustunliklarimiz</h1>
            </div>
            <div className="bottom">
                {cards.map((cards, i) => {
                    return (
                        <div key={i} className="card">
                            <div>
                                <span className="number">0{cards.Number}</span>
                                <h2 className="main-text">{cards.mainText}</h2>
                            </div>
                            <div>
                                <hr />
                                <p className="text">{cards.Text}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Advantages;