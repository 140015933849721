import React, { useState } from "react";
import { Link } from "react-router-dom";

import MonthlyPrices from "./MonthlyPrices";
import YearlyPrices from "./YearlyPrices";

import "../styles/prices.css";
import { useTranslation } from "react-i18next";

function Prices() {
  const [isMonthly, setIsMonthly] = useState(true);
  const {t} = useTranslation();

  return (
    <div className="prices">
      <div className="top">
        <h1>{t("Tariflar")}</h1>
        <p>
          {t("Xush kelibsiz. Boshlashga tayyormisiz? O’z ehtiyojingiz yoki biznesingiz uchun mos tarifni tanlang.")}
        </p>
      </div>
      <div className="center">
        {/* <Link
          to=""
          className={isMonthly ? "mark" : ""}
          onClick={() => setIsMonthly(true)}
        >
          {t("Oylik")}
        </Link>
        <Link
          to=""
          className={!isMonthly ? "mark" : ""}
          onClick={() => setIsMonthly(false)}
        >
          {t("Yillik")}
        </Link> */}
      </div>
      {isMonthly ? <MonthlyPrices /> : <YearlyPrices />}
    </div>
  );
}

export default Prices;
