import "./header.css";

import vector from "../../imgs/Vector.png";
import mic from "../../imgs/micbtnheader.png";

function Header() {
    return (
        <div className="header">
            <div className="pad">
                <span>⭐️ 15 000  foydalanuvchilar</span>
                <h1>Ovozli intellekt bilan dunyoni o‘zgartiring!</h1>
                <div className="btns">
                    <a href="">Bepul sinash</a>
                    <a href="">Ko‘proq bilish <img src={vector} alt="" /></a>
                </div>
            </div>
            <div className="wave">
                <img src={mic} alt="" />
            </div>
        </div>
    );
}

export default Header;