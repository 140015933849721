import "./Partners.css";
import zabon from "../../imgs/zabonlogowhite.png";


function Partners() {
  return (
    <div className="partners">
      <div className="top">
        <h1>Bizning hamkorlarimiz</h1>
      </div>
      <div className="bottom logos">
        <div className="logos-slide">
          <img src={zabon} alt="" />
          <img src={zabon} alt="" />
          <img src={zabon} alt="" />
          <img src={zabon} alt="" />
          <img src={zabon} alt="" />
        </div>
        <div className="logos-slide">
          <img src={zabon} alt="" />
          <img src={zabon} alt="" />
          <img src={zabon} alt="" />
          <img src={zabon} alt="" />
          <img src={zabon} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Partners;