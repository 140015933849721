import React from 'react';
import "../../../styles/Wifi.css";
import { useTranslation } from 'react-i18next';

function Notification({ message, onClose }) {
    const {t} = useTranslation();
    return (
        <div className="error">
            <span className="error__title">{t("Tez orada qo'shiladi")}</span>
            <svg
                className="error__close"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
                width="24px"
                height="24px"
                onClick={onClose}
            >
                <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5 16.59L16.59 17 12 12.41 7.41 17 7 16.59 11.59 12 7 7.41 7.41 7 12 11.59 16.59 7 17 7.41 12.41 12 17 16.59z" />
            </svg>
        </div>
    );
}

export default Notification;
