import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Notification from './pages/Notification';
import Menu from "./pages/menu"

import ServicesNav from './pages/ServicesNav';
import TextToSound from './pages/TextToSound';
import TextToSpeech from './pages/TextToSpeech';
import SpeechToText from './pages/SpeechToText';
import Cloning from './pages/Cloning';
import Dubbing from './pages/Dubbing';

import './scss/CustomServices.css';

function CustomServices() {
    const [showNotification, setShowNotification] = useState(false);

    // Function to handle link click
    const handleLinkClick = () => {
        setShowNotification(true); // Show notification when link is clicked
    };

    // Function to handle closing the notification
    const handleCloseNotification = () => {
        setShowNotification(false); // Hide notification when close button is clicked
    };

    return (
        <div className="services-page">
            {showNotification && (
                <Notification  onClose={handleCloseNotification} />
            )}
            
            <Menu onLinkClick={handleLinkClick} />
            <ServicesNav onLinkClick={handleLinkClick} />
            <div className="right__items">
                <Routes>
                    <Route path="/" element={<Navigate to="/services/text-to-speech" replace />} />
                    <Route path="text-to-speech" element={<TextToSpeech />} />
                    <Route path="text-to-sound" element={<TextToSound />} />
                    <Route path="speech-to-text" element={<SpeechToText />} />
                    <Route path="cloning" element={<Cloning />} />
                    <Route path="dubbing" element={<Dubbing />} />
                </Routes>
            </div>
        </div>
    );
}

export default CustomServices;
