import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import logo from "../imgs/logo.png";
import whatsapp from "../imgs/Whatsapp.png";
import telegram from "../imgs/Telegram.png";
import instagram from "../imgs/Instagram.png";

import "../responsive/1024.css";
import "../responsive/768.css";
import "../responsive/425.css";

function Footer() {
    const { t } = useTranslation();
    return (
        <footer>
            <div className="top">
                <div className="left">
                    <Link to="/">
                        <img src={logo} alt="Logo img" />
                    </Link>
                </div>
                <div className="center">
                    <ul>
                        <li>
                            <Link to="/">{t("Home")}</Link>
                        </li>
                        <li>
                            <Link to="/about">{t("About")}</Link>
                        </li>
                        <li>
                            <Link to="/tts">{t("Services")}</Link>
                        </li>
                        <li>
                            <Link to="/pricing">{t("Pricing")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="right">
                    <ul>
                        <li><a href=""><img src={whatsapp} alt="" /></a></li>
                        <li><a href=""><img src={telegram} alt="" /></a></li>
                        <li><a href=""><img src={instagram} alt="" /></a></li>
                    </ul>
                </div>
            </div>
            <div className="bottom">
                <div className="left">
                    <p>2024 <span>©</span> {t("Zabon Ai. Barcha huquqlar himoyalangan")}</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;