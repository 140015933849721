import React from 'react';
import { useTranslation } from 'react-i18next';

import "../styles/card.css";

function Card() {
    const { t } = useTranslation();
    return (
        <div className='card-registration'>
            <div className="modal">
                <form className="form">
                    <div className="credit-card-info--form">
                        <div className="input_container">
                            <label htmlFor="full_name" className="input_label">{t("Card holder full name")}</label>
                            <input id="full_name" className="input_field" type="text" name="input-name" title="Input title" placeholder={t("Enter your full name")} />
                        </div>
                        <div className="input_container">
                            <label htmlFor="card_number" className="input_label">{t("Card Number")}</label>
                            <input id="card_number" className="input_field" type="number" name="input-name" title="Input title" placeholder="0000 0000 0000 0000" />
                        </div>
                        <div className="input_container">
                            <label htmlFor="expiry_cvv" className="input_label">{t("Expiry Date / CVV")}</label>
                            <div className="split">
                                <input id="expiry_date" className="input_field" type="text" name="input-name" title="Expiry Date" placeholder="01/23" />
                                <input id="cvv" className="input_field" type="number" name="cvv" title="CVV" placeholder="CVV" />
                            </div>
                        </div>
                    </div>
                    <button className="purchase--btn">{t("Confirmation")}</button>
                </form>
            </div>
        </div>
    );
}

export default Card;
