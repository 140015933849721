import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Notification from "./Notification";
import "../styles/login.css";
import { useTranslation } from 'react-i18next';
import GoogleLoginButton from './GoogleLoginButton';

function Login({ setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }

    try {
      const response = await axios.post('https://api.zabonai.uz/dj-rest-auth/login/', {
        username,
        password
      });
      localStorage.setItem('token', response.data.key);
     
      setIsAuthenticated(true);
      // Navigatsiya qilish
      navigate('/services/');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError('Invalid login credentials');
      } else {
        setError('An error occurred. Please try again later.');
      }
    }
  };

  return (
    <div className="registration">
      <div className="login">
        <h1>{t("Login")}</h1>
        {error && <Notification message={error} onClose={() => setError('')} />}
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="username">{t("Username")}</label>
            <input
              type="text"
              className="input"
              placeholder={t("Username")}
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password">{t("Password")}</label>
            <input
              type="password"
              className="input"
              placeholder={t("Password")}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <a href="#" className="item">{t("Forgot password?")}</a>
          </div>
          <div>
            <input type="submit" className="submit" value={t("Log in")} />
            <p className="item two">{t("or continue with")}</p>
          </div>
          <div className="google">
            <GoogleLoginButton />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;