import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Notification from "./Notification";
import "../styles/login.css";
import { useTranslation } from 'react-i18next';
import GoogleLoginButton from './GoogleLoginButton';

function Signup({ setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [fullname, setFullname] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getCSRFToken = () => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, 10) === 'csrftoken=') {
          cookieValue = decodeURIComponent(cookie.substring(10));
          break;
        }
      }
    }
    return cookieValue;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const csrfToken = getCSRFToken();

    // Parol uzunligini tekshirish
    if (password1.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }

    if (password1 !== password2) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post('https://api.zabonai.uz/dj-rest-auth/registration/', {
        fullname,
        username,
        password1,
        password2
      }, {
        headers: {
          'X-CSRFToken': csrfToken,
          'Content-Type': 'application/json'
        }
      });
      localStorage.setItem('token', response.data.key);

      // Authentication holatini yangilash
      setIsAuthenticated(true);

      // Navigatsiya qilish
      navigate('/services/');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError('Username already in use');
      } else {
        setError('An error occurred. Please try again later.');
      }
    }
  };

  return (
    <div className="registration">
      <div className="login">
        <h1>{t("Sign up")}</h1>
        {error && <Notification message={error} onClose={() => setError('')} />}
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="username">{t("Username")}</label>
            <input
              type="text"
              className="input"
              placeholder={t("Username")}
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="fullname">{t("Full Name")}</label>
            <input
              type="text"
              className="input"
              placeholder={t("Full Name")}
              id="fullname"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password1">{t("Password")}</label>
            <input
              type="password"
              className="input"
              placeholder={t("Password")}
              id="password1"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password2">{t("Confirm Password")}</label>
            <input
              type="password"
              className="input"
              placeholder={t("Confirm Password")}
              id="password2"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              required
            />
          </div>
          <div>
            <input type="submit" className="submit" value={t("Sign up")} />
            <p className="item two">{t("or continue with")}</p>
          </div>
          <div className="google">
            <GoogleLoginButton />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signup;