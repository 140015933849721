import React from 'react';
import tick from "../imgs/tick.svg";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function MonthlyPrices() {
    const {t} = useTranslation()
    return (
        <div className='bottom'>
            <div className='card'>
                <div>
                    <h2>{t("Bepul")}</h2>
                    <span>
                        <sub>$</sub><h1>0</h1><sub>/{t("oy")}</sub>
                    </span>
                    <hr />
                    <ul>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("Oyiga 10 minutlik matnni ovozga aylantirish xizmati")}</p>
                        </li>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("4 xil turdagi ovoz")}</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <a href=''>{t("Bepul ulanish")}</a>
                </div>
            </div>
            <div className='card'>
                <div>
                    <h2>{t("Starter")}</h2>
                    <span>
                        <sub>$</sub><h1>5</h1><sub>/{t("oy")}</sub>
                    </span>
                    <hr />
                    <ul>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("Oyiga 30 minutlik matnga ovozga o'zgartirish xizmati")}</p>
                        </li>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("8 xil turdagi ovoz")}</p>
                        </li>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("1 minutdan kichik audiolarni klon qilish xizmati")}</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <Link to='/card'>{t("Boshlash")}</Link>
                </div>
            </div>
            <div className='card'>
                <div>
                    <h2>{t("Pro")}</h2>
                    <span>
                        <sub>$</sub><h1>11</h1><sub>/{t("oy")}</sub>
                    </span>
                    <hr />
                    <ul>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("Oyiga 100 minutlik matnga ovozga o'zgartirish xizmati")}</p>
                        </li>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("Barcha turdagi ovoz")}</p>
                        </li>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("3 minutdan kichik audiolarni klon qilish xizmati")}</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <Link to="/card">{t("Boshlash")}</Link>
                </div>
            </div>
        </div>
    );
}

export default MonthlyPrices;
