import React, { useEffect } from 'react';
import AOS from 'aos';
import { useTranslation } from 'react-i18next';
function Advantages() {
    const {t} = useTranslation();

    useEffect(() => {
        AOS.init({});
    }, []);
    
    return (
        <div className="advantages">
            <div className="top">
                <p>{t("Qulayliklar")}</p>
            </div>
            <div className="bottom">
                <fieldset data-aos="fade-right">
                    <legend>{t("Mijoz uchun")}</legend>
                    <ul>
                        <li>{t("Ovozga tegishli barcha xizmatlar yagona platformada")}</li>
                        <li>{t("Istalgan tilda gaplasha oladi")}</li>
                        <li>{t("Uzoq kuttirmaydi")}</li>
                        <li>{t("Sodda va tushunarli")}</li>
                    </ul>
                </fieldset>
                <fieldset data-aos="fade-left">
                    <legend>{t("Biznes egasi uchun")}</legend>
                    <ul>
                        <li>{t("Ovozga tegishli barcha xizmatlar yagana platformada")}</li>
                        <li>{t("Istalgancha ma'lumot qabul qila oladi, SI tahlil qila oladi")}</li>
                        <li>{t("Hissiyotga berilmaydi")}</li>
                        <li>{t("Arzon va cheklanmagan")}</li>
                    </ul>
                </fieldset>
            </div>
        </div>

    )
}

export default Advantages;