import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import "../styles/sts.css";
import "../responsive/service.css";

import download from "../imgs/download-minimalistic-svgrepo-com.svg";
import play from "../imgs/play.svg";
import pause from "../imgs/pause.svg";
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const Sts = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState("00:00");
  const [duration, setDuration] = useState("00:00");
  const audioRef = useRef(null);
  const playPauseBtnRef = useRef(null);
  const {t} = useTranslation();

  useEffect(() => {
    const audio = audioRef.current;

    const updateCurrentTime = () => {
      setCurrentTime(formatTime(audio.currentTime));
    };

    const updateDuration = () => {
      setDuration(formatTime(audio.duration));
    };

    audio.addEventListener('timeupdate', updateCurrentTime);
    audio.addEventListener('loadedmetadata', updateDuration);

    return () => {
      audio.removeEventListener('timeupdate', updateCurrentTime);
      audio.removeEventListener('loadedmetadata', updateDuration);
    };
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="sts">
      <div className="container">
        <div className="top">
          <h1>{t("Ovozni boshqa tilga o'g'irish")}</h1>
        </div>
        <div className="center">
          <Link to="/tts">TTS</Link>
          <Link to="/text-to-sound">Text to sound</Link>
          <Link to="#">STT</Link>
          <Link to="#" className="mark">STS</Link>
          <Link to="#">{t("Ovozni klonlash")}</Link>
        </div>
        <div className="tools">
          <div className="tool">
            <div className="upload">
              <div className="upload_top">
                <p>{t("Faylni yuklash")}</p>
              </div>
              <div className="upload-bottom">
                <form className="file-upload-form">
                  <label htmlFor="file" className="file-upload-label">
                    <div className="file-upload-design">
                      <svg viewBox="0 0 640 512" height="1em">
                        <path
                          d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z">
                        </path>
                      </svg>
                      <p>{t("Drag and Drop")}</p>
                      <p>{t("or")}</p>
                      <span className="browse-button">{t("Browse file")}</span>
                    </div>
                    <input id="file" type="file" />
                  </label>
                </form>
              </div>
            </div>
          </div>
          <div className="tool">
            <form action="">
              <div className="language">
                <div className="language-top">
                  <p>{t("Tilni tanlang")}</p>
                  <button>{t("O'girish")}</button>
                </div>
                <div className="language-bottom">
                  <select name="" id="">
                    <option value="">Ingliz tili</option>
                    <option value="">Rus tili</option>
                    <option value="">Xitoy tili</option>
                    <option value="">Fransuz tili</option>
                    <option value="">Portugal tili</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          <div className="tool">
            <div className="result">
              <div className="result-top">
                <p>{t("Audio fayl")}</p>
              </div>
              <div className="result-bottom">
                <div className="audio-player">
                  <button
                    className="play-btn"
                    id="playPauseBtn"
                    onClick={handlePlayPause}
                    ref={playPauseBtnRef}
                  >
                    <img src={isPlaying ? pause : play} width="20" />
                  </button>
                  <div className="track-info">
                    <div className="track-title">Musiqa nomi</div>
                    <div className="track-time">
                      <span id="currentTime">{currentTime}</span> /
                      <span id="duration">{duration}</span>
                    </div>
                  </div>
                  <a className="download-btn" href="your-audio-file.mp3" download>
                    <img src={download} width="24" alt="" />
                  </a>
                  <audio id="audio" ref={audioRef} src></audio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sts;
