import { useEffect } from 'react';

import hemis from "../imgs/Hemis.png";
import two from "../imgs/image_2024-06-27_11-16-28.png";
import unilibrary from "../imgs/unilibrary.png";
import four from "../imgs/image_2024-06-27_11-28-51.png";
import sinerygy from "../imgs/sinergy.png";

import "../style.css";
import "../responsive/1024.css";
import "../responsive/768.css";
import "../responsive/425.css";

import 'aos/dist/aos.css';
import AOS from 'aos';
import { useTranslation } from 'react-i18next';

function Componies() {
    const {t} = useTranslation();

    useEffect(() => {
        AOS.init({});
      }, []);
    
    return (
        <div className="componies">
            <div className="top">
                <p>{t("Bizga ishonch bildirganlar:")}</p>
            </div>
            <div className="logos" data-aos="fade-up">
                <div className="logos-slide">
                    <img src={hemis} alt="" />
                    <img src={two} alt="" />
                    <img src={unilibrary} alt="" />
                    <img src={four} alt="" />
                    <img src={sinerygy} alt="" />
                </div>
                <div className="logos-slide">
                    <img src={hemis} alt="" />
                    <img src={two} alt="" />
                    <img src={unilibrary} alt="" />
                    <img src={four} alt="" />
                    <img src={sinerygy} alt="" />
                </div>
                <div className="logos-slide">
                    <img src={hemis} alt="" />
                    <img src={two} alt="" />
                    <img src={unilibrary} alt="" />
                    <img src={four} alt="" />
                    <img src={sinerygy} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Componies;