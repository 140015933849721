import React, {useEffect} from 'react';
import AOS from 'aos';

import aboutimg from "../imgs/aboutimg.png"

import "../styles/about.css";
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

function AboutPage() {
    useEffect(() => {
        AOS.init({});
      }, []);

      const {t} = useTranslation();

    return (
        <div className='AboutPage' data-aos='fade-right'>
            <div className="left">
                <h1>{t("Biz haqimizda")}</h1>
                <p>{t("Zabon.ai bu ultra realistik ovozli chatbot.U bir vaqtning o’zida bir nechta ovoz va matnlarni qayta ishlay oladi. Bundan tashqari aniq va samarali konvertatsiyalarni ta’minlaydi, yuqori sifatli audio fayllarni taqdim qiladi. Bu loyiha nafaqat real nutqni yaratadi. Ushbu loyihaning boshida kuchli ovozli sun'iy intellekt yotadi, u nafaqat inson nutqini taqlid qilishga, balki murakkab so'rovlarni tushunish va ularga noziklik va ravonlik bilan javob berishga qodir.")}</p>
            </div>
            <div className="right" data-aos='fade-left'>
                <img src={aboutimg} alt="" />
            </div>
        </div>
    );
}

export default AboutPage;