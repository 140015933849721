import React from 'react';
import tick from "../imgs/tick.svg";
import { Link } from 'react-router-dom';
import { t } from 'i18next';

function YearlyPrices() {
    return (
        <div className='bottom'>
            <div className='card'>
                <div>
                    <h2>{t("Bepul")}</h2>
                    <span>
                        <sub>$</sub><h1>0</h1><sub>/{t("yil")}</sub>
                    </span>
                    <hr />
                    <ul>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("Free membership")}</p>
                        </li>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("30GB Storage")}</p>
                        </li>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("1 Personal Email")}</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <a href=''>{t("Bepul ulanish")}</a>
                </div>
            </div>
            <div className='card'>
                <div>
                    <h2>{t("Premium")}</h2>
                    <span>
                        <sub>$</sub><h1>100</h1><sub>/{t("yil")}</sub>
                    </span>
                    <hr />
                    <ul>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("Premium membership")}</p>
                        </li>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("100GB Storage")}</p>
                        </li>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("5 Personal Emails")}</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <Link to='/card'>{t("Boshlash")}</Link>
                </div>
            </div>
            <div className='card'>
                <div>
                    <h2>Pro</h2>
                    <span>
                        <sub>$</sub><h1>200</h1><sub>/{t("yil")}</sub>
                    </span>
                    <hr />
                    <ul>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("Pro membership")}</p>
                        </li>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("1TB Storage")}</p>
                        </li>
                        <li>
                            <img src={tick} alt="tick" />
                            <p>{t("Unlimited Personal Emails")}</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <Link to='/card'>{t("Boshlash")}</Link>
                </div>
            </div>
        </div>
    );
}

export default YearlyPrices;
