import React, { useTransition } from "react";

import "../responsive/1024.css";
import "../responsive/425.css";
import "../responsive/768.css";
import { useTranslation } from "react-i18next";

function Services() {
  const {t} = useTranslation();
  return (
    <div class="services-main">
      <div class="top">
        <p>{t("Sizga quyidagilarni bera olamiz:")}</p>
      </div>

      <div className="bottom">
        <div className="left" data-aos="fade-right">
          <div className="text">
            <h1>{t("Ovozni klonlash")}</h1>
            <p>
              {t("Bu xizmatimiz orqali siz istalgan ovozni boshqa odamning ovoziga aylantirishingiz mumkin bo'ladi.")}
            </p>
          </div>
        </div>
        <div className="right">
          <div className="service__item" data-aos="fade-left">
            <div></div>
            <div class="text">
              <h1>{t("Ovozni boshqa tilga o'g'irish")}</h1>
              <p>{t("Bu xizmatimiz orqali siz ovozingizni boshqa tillarga o'zgartirishingiz mumkin.")}</p>
            </div>
          </div>
          <div className="service__items" data-aos="fade-up">
            <div>
              <h1>{t("Matnni ovozga o'zgartirish")}</h1>
            </div>
            <div>
              <h1>{t("Ovozni matnga o'zgartirish")}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Services;