import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Footer.css";

import logo from "../../imgs/logo.png";
import whatsapp from "../../imgs/Whatsapp.png";
import telegram from "../../imgs/Telegram.png";
import instagram from "../../imgs/Instagram.png";


function Footer() {
    const { t } = useTranslation();
    return (
        <footer>
            <div className="top">
                <div className="left">
                    <Link to="/">
                        <img src={logo} alt="Logo img" />
                    </Link>
                </div>
                <div className="right">
                    <ul>
                        <li><a href=""><img src={whatsapp} alt="" /></a></li>
                        <li><a href=""><img src={telegram} alt="" /></a></li>
                        <li><a href=""><img src={instagram} alt="" /></a></li>
                    </ul>
                </div>
            </div>
            <div className="bottom">
                <div className="left">
                    <p>2024 <span>©</span> {t("Zabon Ai. Barcha huquqlar himoyalangan")}</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;