import "./HowWork.css";

import mic from "../../imgs/playpink.png";

const items = [
    {head: "Ro‘yxatdan o‘ting", text: "Yangi akkaunt yarating va kerakli tuzatishlarni bajaring."},
    {head: "Sizga keraklisini toping", text: "Saytda sizga kerakli bo’limni toping va  undagi sozlamalarni to’g’rilang."},
    {head: "Ovozni tahrirlashni boshlang", text: "Hammasi to’g’ri ekanligiga ishonch hosil qiling va yaratish tugmasini bosing."}
];

function HowWork() {
    return (
        <div className="HowWork">
            <div className="top">
                <h1>Qanday ishlatish mumkin</h1>
            </div>
            <div className="bottom">
                <div className="wave">
                    <img src={mic} alt="" />
                </div>
                <div className="items">
                    {items.map((item, index) => (
                        <div key={index} className="item">
                            <h2 className="head">{item.head}</h2>
                            <p className="text">{item.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default HowWork;