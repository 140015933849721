import React, { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Correct way to import jwtDecode
import { useNavigate } from 'react-router-dom';

function GoogleLoginButton() {
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.google) {
        clearInterval(interval);

        window.google.accounts.id.initialize({
          client_id: '444466505195-c0n0uc52thfkl9fgfka9ham5no0jb9ke.apps.googleusercontent.com',
          callback: handleCredentialResponse,
        });

        window.google.accounts.id.renderButton(
          document.getElementById('googleSignInButton'),
          { theme: 'outline', size: 'large' }
        );
      }
    }, 100); 

    return () => clearInterval(interval);
  }, []);

  const handleCredentialResponse = (response) => {
    const userObject = jwtDecode(response.credential);
    console.log(userObject);

    // Save user information and token in localStorage
    localStorage.setItem('user', JSON.stringify(userObject));
    localStorage.setItem('token', response.credential);


    navigate('/services');

    // Reload the page to ensure state updates correctly
    window.location.reload(); // Forces the page to reload after navigation
  };

  return <div id="googleSignInButton"></div>;
}

export default GoogleLoginButton;
