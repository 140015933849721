// Preloader.js
import React from 'react';
import preloaderGif from '../imgs/preloader.gif'; 
import "../styles/preloader.css"

const Preloader = () => {
  return (
    <div className="preloader">
      <img src={preloaderGif} alt="Loading..." />
    </div>
  );
};

export default Preloader;
