import { Link } from "react-router-dom";

import "./Navbar.css"

import hamburberMenu from "../../imgs/burger-menu-svgrepo-com.svg";
import logo from "../../imgs/logo.png";
import { useTranslation } from "react-i18next";

function Navbar() {
    const { t } = useTranslation();

    return (
        <nav className="nav">
            <div className="left">
                <Link to="/">
                    <img src={logo} alt="Logo rasmi" height={36} />
                </Link>
            </div>
            <div className="center">
                <ul>
                    <li>
                        <Link to="/">{t("Home")}</Link>
                    </li>
                    <li>
                        <Link to="/about">{t("About")}</Link>
                    </li>
                    <li>
                        <Link to="/tts">{t("Services")}</Link>
                    </li>
                    <li>
                        <Link to="/pricing">{t("Pricing")}</Link>
                    </li>
                </ul>
            </div>
            <div className="right">
                <a href="">Kirish</a>
                <a href="">Ro'yxatdan o'tish</a>
                <svg width="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 18L20 18" stroke="#fff" stroke-width="2" stroke-linecap="round" />
                    <path d="M4 12L20 12" stroke="#fff" stroke-width="2" stroke-linecap="round" />
                    <path d="M4 6L20 6" stroke="#fff" stroke-width="2" stroke-linecap="round" />
                </svg>
            </div>
        </nav>
    );
}

export default Navbar;