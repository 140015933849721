import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeaderText from "./HeaderText";
import Head from "./Head"

import "../style.css";
import "../responsive/1024.css";
import "../responsive/768.css";
import "../responsive/425.css";

function Header() {
  const { t } = useTranslation();

  return (
    <header>
      <div className="left-head" data-aos="fade-right">
        <h2>{t("AI ovoz generatori: eng real AI platformasi!")}</h2>
        <h1>{t("Mijozlaringizni hayratda qoldiradigan giper realistik AI ovoz generatori")}</h1>
        <Link to="/tts">{t("Hoziroq boshlash")}</Link>
      </div>
      <div className="right-head" data-aos="fade-left">
        <Head/>
      </div>
    </header>
  );
}

export default Header;
