import React from 'react';

import "../style.css";
import "../responsive/1024.css";
import "../responsive/768.css";
import "../responsive/425.css";
import { useTranslation } from 'react-i18next';

function Guide() {
    const { t} = useTranslation()

    return (
        <div className="guide">
            <div className="left" data-aos="fade-right">
                <h1>{t("Biz haqimizda")}</h1>
                <p>{t("Loyiha haqida qisqacha vlog.")}</p>
            </div>
            <div className="right" data-aos="fade-left">
                <iframe width="100%" height="400" src="https://www.youtube.com/embed/U6k6rkGlCVw?si=SzEMis_c80vRRcpz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
    )
}

export default Guide;
