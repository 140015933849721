import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";
import About from "../About/About";
import Partners from "../Partners/Partners";
import Advantages from "../Advantages/Advantages";
import HowWork from "../HowWork/HowWork";
import TryNow from "../TryNow/TryNow";
import Footer from "../Footer/Footer";
import Comments from "../Comments/Comment";

function Index() {
    return (
        <div style={{ backgroundColor: '#101010' }}>
            <Navbar />
            <Header />
            <About />
            <Partners />
            <Advantages />
            <HowWork />
            <Comments />
            <TryNow />
            <Footer />
        </div>
    );
}

export default Index;