import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import aboutimg from "../imgs/about-page-img.png"

import "../style.css";
import "../responsive/1024.css";
import "../responsive/768.css";
import "../responsive/425.css";

function About() {
    const { t } = useTranslation();

    return (
        <div className="about">

            <div className="left" data-aos="fade-right">
                <h1>
                    <span>Zabon.ai</span> {t("bu ultra realistik ovozli AI.")}
                </h1>
                <p>{t("U bir vaqtning o’zida minglab ovoz va matnlarni qayta ishlay oladi. Bundan tashqari aniq va samarali konvertatsiyalarni ta’minlaydi, yuqori sifatli audio fayllarni taqdim qiladi.")}</p>
            </div>
            <div className="right" data-aos="fade-left">
                <img src={aboutimg} alt="" />
            </div>
        </div>

    )
}

export default About
