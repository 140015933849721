import React, { useRef, useEffect } from 'react';
import Typed from 'typed.js';
import { useTranslation } from 'react-i18next';

function HeaderText() {
    const { t, i18n } = useTranslation();
    const typedElement = useRef(null);
    const typedInstance = useRef(null);

    useEffect(() => {
        const strings = [
            t("clone your voice."),
            t("convert text to speech."),
            t("convert speech to text."),
            t("translate your voice.")
        ];

        const options = {
            strings: strings,
            typeSpeed: 75,
            backSpeed: 75,
            loop: true,
        };

        typedInstance.current = new Typed(typedElement.current, options);

        return () => {
            if (typedInstance.current) {
                typedInstance.current.destroy();
            }
        };
    }, [i18n.language]); 

    return (
        <>
            <h1><span>Zabon.ai</span> - <br /><span ref={typedElement}></span></h1>
        </>
    );
}

export default HeaderText;
