import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import "../style.css";
import "../responsive/1024.css";
import "../responsive/768.css";
import "../responsive/425.css";

import close from "../imgs/close.png";

function Hamburger() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  const CloseMenu = () => {
    document.querySelector(".hamburger").style.display = "none";
  }

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    navigate('/login');
  };

  useEffect(() => {
    setIsLoggedIn(!!localStorage.getItem('token'));
  }, [isLoggedIn]);

  return (
    <div className="hamburger">
      <div className="container">
        <div className="top">
          <div className="left">
            {isLoggedIn ? (
              <button onClick={handleLogout} className="logout">
                {t("Logout")}
              </button>
            ) : (
              <>
                <Link to="/login" className="login">{t("Log in")}</Link>
                <Link to="/signup" className="signup">{t("Sign Up")}</Link>
              </>
            )}
          </div>
          <div className="right">
            <img src={close} onClick={CloseMenu} width={20} alt />
          </div>
        </div>
        <div className="bottom">
          <ul>
            <ul>
              <li>
                <Link to="/">{t("Home")}</Link>
              </li>
              <li>
                <Link to="/about">{t("About")}</Link>
              </li>
              <li>
                <Link to="/tts">{t("Services")}</Link>
              </li>
              <li>
                <Link to="/pricing">{t("Pricing")}</Link>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Hamburger;