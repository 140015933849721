import "./Comments.css";

import user1 from "../../imgs/user1.png"

const comments = [
    { commentText: "Ovoz bilan ishlovchi  sun’iy intellekt mening ishimni butunlay o'zgartirdi. Endi bir necha daqiqada ovozlarni klonlashim mumkin", userImage: user1, userName: "Nodir Nizomov", userJob: "SMM menejer" },
    { commentText: "Ovoz bilan ishlovchi  sun’iy intellekt mening ishimni butunlay o'zgartirdi. Endi bir necha daqiqada ovozlarni klonlashim mumkin", userImage: user1, userName: "Nodir Nizomov", userJob: "SMM menejer" },
    { commentText: "Ovoz bilan ishlovchi  sun’iy intellekt mening ishimni butunlay o'zgartirdi. Endi bir necha daqiqada ovozlarni klonlashim mumkin", userImage: user1, userName: "Nodir Nizomov", userJob: "SMM menejer" }
];

function Comments() {
    return (
        <div className="comments">
            <div className="top">
                <h1>Mijozlar fikri</h1>
            </div>
            <div className="bottom">
                <div className="comments-wrapper">
                    {comments.map((comment, index) => (
                        <div key={index} className="comment">
                            <div className="content">
                                <h2>{comment.commentText}</h2>
                            </div>
                            <div className="user">
                                <div>
                                    <img src={comment.userImage} alt="user" />
                                </div>
                                <div>
                                    <p>{comment.userName}</p>
                                    <span>{comment.userJob}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Comments;