import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';

import "../styles/team.css";
import islomiddin from "../imgs/islomiddin.jpg";
import azizbek from "../imgs/azizbek.png";
import murodjon from "../imgs/murodjon.jpg";
import javlon from "../imgs/Javlon.png";
import muhammadjon from "../imgs/mukhamdjon.jpg";

function Team() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({});
  }, []);

  return (
    <div className='team'>
      <div className='top' data-aos="fade-up">
        <h1>{t("Bizning jamoa")}</h1>
        <p>{t("Jamoamizda o’z ishining mohir ustalari to’plangan. Ularning har birida bir necha yillik tajriba mavjud va sizlar uchun doim xizmatda.")}</p>
      </div>
      <div className='bottom'>
        <div className='card' data-aos="fade-up">
          <div className='cardTop' style={{ backgroundImage: `url(${islomiddin})` }}></div>
          <div className='cardBottom'>
            <h2>Islomiddin Mamasodiqov</h2>
            <p>Ceo</p>
          </div>
        </div>
        <div className='card' data-aos="fade-up">
          <div className='cardTop' style={{ backgroundImage: `url(${azizbek})` }}></div>
          <div className='cardBottom'>
            <h2>Azizbek G'ulomov</h2>
            <p>{t("Frontend dasturchi")}</p>
          </div>
        </div>
        <div className='card' data-aos="fade-up">
          <div className='cardTop' style={{ backgroundImage: `url(${murodjon})` }}></div>
          <div className='cardBottom'>
            <h2>Murodjon Isroilov</h2>
            <p>{t("Backend dasturchi")}</p>
          </div>
        </div>
        <div className='card' data-aos="fade-up">
          <div className='cardTop' style={{ backgroundImage: `url(${javlon})` }}></div>
          <div className='cardBottom'>
            <h2>Javlon Mustafoyev</h2>
            <p>{t("Dizayner")}</p>
          </div>
        </div>
        <div className='card' data-aos="fade-up">
          <div className='cardTop' style={{ backgroundImage: `url(${muhammadjon})` }}></div>
          <div className='cardBottom'>
            <h2>Shaxboz Ahmadaliyev</h2>
            <p>DevOps</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
