import "./TryNow.css";

function TryNow() {
    return (
        <div className="trynow">
            <div className="container">
                <h1>Hoziroq sinab ko‘ring</h1>
                <p>Ovozlarni sun’iy intellekt yordamida yaratishga tayyormisiz ?</p>
                <div>
                    <a href="">Bepul sinash</a>
                    <a href="">Xizmatlar</a>
                </div>
            </div>
        </div>
    );
}

export default TryNow;