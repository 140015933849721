import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import download from "../../../imgs/download-minimalistic-svgrepo-com.svg";
import play from "../../../imgs/play.svg";
import pause from "../../../imgs/pause.svg";

import "../scss/CustomServices.css"

function TextToSpeech() {
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTime, setCurrentTime] = useState("00:00");
	const [duration, setDuration] = useState("00:00");
	const [text, setText] = useState("");
	const [selectedVoice, setSelectedVoice] = useState("EXAVITQu4vr4xnSDxMaL");
	const [stability, setStability] = useState(1);
	const [similarity, setSimilarity] = useState(1);
	const [audioUrl, setAudioUrl] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const audioRef = useRef(null);
	const { t } = useTranslation();

	useEffect(() => {
		const audio = audioRef.current;

		const updateCurrentTime = () => {
			setCurrentTime(formatTime(audio.currentTime));
		};

		const updateDuration = () => {
			setDuration(formatTime(audio.duration));
		};

		const handleAudioEnd = () => {
			setIsPlaying(false);
			setCurrentTime("00:00");
		};

		audio.addEventListener('timeupdate', updateCurrentTime);
		audio.addEventListener('loadedmetadata', updateDuration);
		audio.addEventListener('ended', handleAudioEnd);

		return () => {
			audio.removeEventListener('timeupdate', updateCurrentTime);
			audio.removeEventListener('loadedmetadata', updateDuration);
			audio.removeEventListener('ended', handleAudioEnd);
		};
	}, []);

	const formatTime = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const secs = Math.floor(seconds % 60);
		return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
	};

	const handlePlayPause = () => {
		const audio = audioRef.current;
		if (isPlaying) {
			audio.pause();
		} else {
			audio.play().catch(error => {
				console.error("Audio playback failed:", error);
				setIsPlaying(false);
			});
		}
		setIsPlaying(!isPlaying);
	};

	const handleTextChange = (e) => {
		setText(e.target.value);
	};

	const handleVoiceChange = (e) => {
		setSelectedVoice(e.target.value);
	};

	const handleStabilityChange = (e) => {
		setStability(e.target.value);
	};

	const handleSimilarityChange = (e) => {
		setSimilarity(e.target.value);
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const response = await axios.post('https://api.zabonai.uz/text-to-speech/', {
				stability: stability,
				similarity: similarity,
				style: 1,
				speaker_boost: true,
				text: text,
				voice_type: selectedVoice
			}, {
				headers: {
					'accept': 'application/json',
					'Content-Type': 'application/json',
					'X-CSRFToken': 'J8s219XtTMYkpdOkBKu1QEphvGVkTNuEF6rX7fN0lm6QBlxexnnwAAGPVa1SWOrf'
				}
			});

			console.log('API response:', response.data);

			if (response.data && response.data.result_audio) {
				const audioUrl = response.data.result_audio;

				// Fayl nomini olish
				const fileName = audioUrl.split('/').pop();

				setAudioUrl(audioUrl);
				audioRef.current.src = audioUrl;
				audioRef.current.load();
				document.getElementById('track-title').textContent = fileName;
			} else {
				console.error('Invalid API response:', response.data);
			}
		} catch (error) {
			console.error('Error:', error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="speech">
			<div className="stt sts tts">
				<div className="container">
					<div className="top">
						<h1>{t("Matnni ovozga o'zgartirish")}</h1>
					</div>
					{/* <div className="center">
                        <Link to="/services/text-to-speech" className="mark">TTS</Link>
                        <Link to="/services/speech-to-text">STT</Link>
                    </div> */}
					<div className="tools">
						<div className="tool">
							<div className="text">
								<div className="text-top">
									<p>{t("Kerakli matnni kiriting")}</p>
								</div>
								<div className="text-bottom">
									<form onSubmit={(e) => e.preventDefault()}>
										<textarea
											value={text}
											onChange={handleTextChange}
											placeholder={t("Matn kiritishigiz mumkin...")}
										></textarea>
									</form>
								</div>
							</div>
						</div>
						<div className="tool">
							<form onSubmit={(e) => e.preventDefault()}>
								<div className="voice">
									<div className="voice-top">
										<p>{t("Ovozni tanlang")}</p>
										<button type="button" onClick={handleSubmit} disabled={isLoading}>
											{isLoading ? (
												<div className="spinner"></div>
											) : t("O'girish")}
										</button>

									</div>
									<div className="voice-bottom">
										<select value={selectedVoice} onChange={handleVoiceChange}>
											<option value="EXAVITQu4vr4xnSDxMaL">Sarah</option>
											<option value="FGY2WhTYpPnrIDTdsKH5">Laura</option>
											<option value="IKne3meq5aSn9XLyUdCD">Charlie</option>
											<option value="JBFqnCBsd6RMkjVDRZzb">George</option>
											<option value="N2lVS1w4EtoT3dr4eOWO">Callum</option>
											<option value="TX3LPaxmHKxFdv7VOQHJ">Liam</option>
											<option value="XB0fDUnXU5powFXDhCwa">Charlotte</option>
											<option value="Xb7hH8MSUJpSbSDYk0k2">Alice</option>
											<option value="XrExE9yKIg1WjnnlVkGX">Matilda</option>
											<option value="bIHbv24MWmeRgasZH58o">Will</option>
											<option value="cgSgspJ2msm6clMCkdW9">Jessica</option>
											<option value="cjVigY5qzO86Huf0OWal">Eric</option>
											<option value="iP95p4xoKVk53GoZ742B">Chris</option>
											<option value="nPczCjzI2devNBz1zQrb">Brian</option>
											<option value="onwK4e9ZLuTAKqWW03F9">Daniel</option>
											<option value="pFZP5JQG7iQjIQuC4Bku">Lily</option>
											<option value="pqHfZKP75CvOlQylNhV4">Bill</option>
										</select>
										<div>
											<label htmlFor="stability">{t("Stability")}</label>
											<input
												type="range"
												className="stability"
												id="stability"
												min="0"
												max="2"
												step="0.1"
												value={stability}
												onChange={handleStabilityChange}
											/>
											<label htmlFor="similarity">{t("Similarity")}</label>
											<input
												type="range"
												className="similarity"
												id="similarity"
												min="0"
												max="2"
												step="0.1"
												value={similarity}
												onChange={handleSimilarityChange}
											/>
										</div>
									</div>
								</div>
							</form>
						</div>
						<div className="tool">
							<div className="result">
								<div className="result-top">
									<p>{t("Audio fayl")}</p>
								</div>
								<div className="result-bottom">
									<div className="audio-player">
										<button className="play-btn" onClick={handlePlayPause}>
											<img src={isPlaying ? pause : play} width="20" alt="Play/Pause" />
										</button>
										<div className="track-info">
											<div className="track-title">{t("Audio fayl")}</div>
											<div className="track-time">
												<span id="currentTime">{currentTime}</span> /
												<span id="duration">{duration}</span>
											</div>
										</div>
										{audioUrl && (
											<a className="download-btn" href={audioUrl} download={audioUrl}>
												<img src={download} width="24" alt="Download" />
											</a>
										)}
										<audio
											ref={audioRef}
											id="audio"
											src={audioUrl}
											onError={(e) => console.error('Audio error:', e)}
										></audio>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TextToSpeech;