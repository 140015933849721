import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/stt.css';
import "../styles/sts.css";
import { useTranslation } from 'react-i18next';

function copyText() {
    const copyText = document.querySelector('.result-text-bottom .container p').innerText;
    navigator.clipboard.writeText(copyText).then(function () {
        console.log('Text copied to clipboard');
    }).catch(function (err) {
        console.error('Error in copying text: ', err);
    });

}

function Stt() {
    const {t} = useTranslation();
    return (
        <div className="stt sts">
            <div className="container">
                <div className="top">
                    <h1>{t("Ovozni matnga o'zgartirish")}</h1>
                </div>
                <div className="center">
                    <Link to="/tts">TTS</Link>
                    <Link to="/text-to-sound">Text to sound</Link>
                    <Link to="#" className="mark">STT</Link>
                    <Link to="#">STS</Link>
                    <Link to="#">{t("Ovozni klonlash")}</Link>
                </div>
                <div className="tools">
                    <div className="file">
                        <form action="">
                            <div className="file-top">
                                <p>{t("Faylni yuklash")}</p>
                                <button type="button">{t("O'girish")}</button>
                            </div>
                            <div className="file-bottom">
                                <div className="file-upload-form">
                                    <label htmlFor="file" className="file-upload-label">
                                        <div className="file-upload-design">
                                            <svg viewBox="0 0 640 512" height="1em">
                                                <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                                            </svg>
                                            <p>{t("Drag and Drop")}</p>
                                            <p>{t("or")}</p>
                                            <span className="browse-button">{t("Browse file")}</span>
                                        </div>
                                        <input id="file" type="file" />
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="result-text">
                        <div className="result-text-top">
                            <p>{t("Yaratilgan matn")}</p>
                            <button className="copy" onClick={copyText}>
                                <span data-text-end="Copied!" data-text-initial="Copy to clipboard" className="tooltip"></span>
                                <span>
                                    <svg xmlSpace="preserve" style={{ enableBackground: 'new 0 0 512 512' }} viewBox="0 0 6.35 6.35" y={0} x={0} height={20} width={20} xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg" className="clipboard">
                                        <g>
                                            <path fill="currentColor" d="M2.43.265c-.3 0-.548.236-.573.53h-.328a.74.74 0 0 0-.735.734v3.822a.74.74 0 0 0 .735.734H4.82a.74.74 0 0 0 .735-.734V1.529a.74.74 0 0 0-.735-.735h-.328a.58.58 0 0 0-.573-.53zm0 .529h1.49c.032 0 .049.017.049.049v.431c0 .032-.017.049-.049.049H2.43c-.032 0-.05-.017-.05-.049V.843c0-.032.018-.05.05-.05zm-.901.53h.328c.026.292.274.528.573.528h1.49a.58.58 0 0 0 .573-.529h.328a.2.2 0 0 1 .206.206v3.822a.2.2 0 0 1-.206.205H1.53a.2.2 0 0 1-.206-.205V1.529a.2.2 0 0 1 .206-.206z"></path>
                                        </g>
                                    </svg>
                                    <svg xmlSpace="preserve" style={{ enableBackground: 'new 0 0 512 512' }} viewBox="0 0 24 24" y={0} x={0} height={18} width={18} xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg" className="checkmark">
                                        <g>
                                            <path data-original="#000000" fill="currentColor" d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"></path>
                                        </g>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="result-text-bottom">
                            <div className="container">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque sapiente, nulla ipsum, repudiandae illo sequi incidunt atque laborum soluta necessitatibus laboriosam placeat obcaecati facere ipsa possimus nesciunt beatae saepe, fuga corporis natus deleniti quis asperiores iure! Esse repellat id similique commodi voluptas architecto est iure odio, totam tenetur. Possimus, explicabo nesciunt! Placeat id enim perferendis cum sequi quam dolores incidunt commodi? Nesciunt saepe fugit neque beatae, placeat perspiciatis velit ducimus corrupti rerum ipsa magnam laboriosam voluptates maxime eligendi cum obcaecati error ut hic nisi aliquid accusamus alias. Exercitationem consequatur aperiam enim labore eveniet laborum eum. Ullam sint nulla magnam animi porro laboriosam vitae ipsa ipsam, quis sapiente perspiciatis nihil quod obcaecati, corrupti soluta ea minus dolorum fuga recusandae? Atque esse recusandae voluptatem pariatur ratione illo natus vel expedita delectus. Qui, possimus ipsa. Minus doloremque pariatur nobis explicabo harum iusto obcaecati voluptas facilis rerum, quaerat dolore autem! Repellat molestias tempora optio commodi accusantium necessitatibus eos ipsam fugit in rem illo aperiam, nesciunt ducimus ipsum deserunt, ea maiores debitis aut. In velit perferendis eos at pariatur mollitia magni. Odit aliquid eos tempora non dolore est vitae blanditiis officia, architecto maiores eveniet magnam? Quas nobis ab dignissimos harum dolorem. Repudiandae ut illum ea!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Stt;
