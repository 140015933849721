import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import download from "../../../imgs/download-minimalistic-svgrepo-com.svg";
import play from "../../../imgs/play.svg";
import pause from "../../../imgs/pause.svg";

import "../scss/CustomServices.css"

function TextToSound() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState("00:00");
  const [duration, setDuration] = useState("00:00");
  const [text, setText] = useState("");
  const [selectedVoice, setSelectedVoice] = useState("EXAVITQu4vr4xnSDxMaL");
  const [promptInfluence, setPromptInfluence] = useState(1);
  const [audioDuration, setAudioDuration] = useState(2);
  const [audioUrl, setAudioUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const audioRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const audio = audioRef.current;

    const updateCurrentTime = () => {
      setCurrentTime(formatTime(audio.currentTime));
    };

    const updateDuration = () => {
      setDuration(formatTime(audio.duration));
    };

    const handleAudioEnd = () => {
      setIsPlaying(false);
      setCurrentTime("00:00");
    };

    audio.addEventListener('timeupdate', updateCurrentTime);
    audio.addEventListener('loadedmetadata', updateDuration);
    audio.addEventListener('ended', handleAudioEnd);

    return () => {
      audio.removeEventListener('timeupdate', updateCurrentTime);
      audio.removeEventListener('loadedmetadata', updateDuration);
      audio.removeEventListener('ended', handleAudioEnd);
    };
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play().catch(error => {
        console.error("Audio playback failed:", error);
        setIsPlaying(false);
      });
    }
    setIsPlaying(!isPlaying);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleVoiceChange = (e) => {
    setSelectedVoice(e.target.value);
  };

  const handlePromptInfluenceChange = (e) => {
    setPromptInfluence(e.target.value);
  };

  const handleAudioDurationChange = (e) => {
    setAudioDuration(e.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://api.zabonai.uz/text-to-sound/', {
        prompt_influence: promptInfluence,
        duration_seconds: audioDuration,
        style: 1,
        speaker_boost: true,
        text: text,
        voice_type: selectedVoice
      }, {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': 'J8s219XtTMYkpdOkBKu1QEphvGVkTNuEF6rX7fN0lm6QBlxexnnwAAGPVa1SWOrf'
        }
      });

      console.log('API response:', response.data);

      if (response.data && response.data.result_audio) {
        const audioUrl = response.data.result_audio;
        const fileName = audioUrl.split('/').pop();

        setAudioUrl(audioUrl);
        audioRef.current.src = audioUrl;
        audioRef.current.load();
        document.getElementById('track-title').textContent = fileName;
        console.log('Audio URL:', audioUrl);

      } else {
        console.error('Invalid API response:', response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="speech">
      <div className="stt sts tts">
        <div className="container">
          <div className="top">
            <h1>{t("Matnni tovushga o'zgartirish")}</h1>
          </div>
          <div className="tools">
            <div className="tool">
              <div className="text">
                <div className="text-top">
                  <p>{t("Kerakli matnni kiriting")}</p>
                </div>
                <div className="text-bottom">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <textarea
                      value={text}
                      onChange={handleTextChange}
                      placeholder={t("Matn kiritishigiz mumkin...")}
                    ></textarea>
                  </form>
                </div>
              </div>
            </div>
            <div className="tool">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="voice">
                  <div className="voice-top">
                    <p>{t("Ovozni tanlang")}</p>
                    <button type="button" onClick={handleSubmit} disabled={isLoading}>
                      {isLoading ? (
                        <div className="spinner"></div>
                      ) : t("O'girish")}
                    </button>
                  </div>
                  <div className="voice-bottom">
                    <div>
                      <label htmlFor="promptInfluence">{t("Prompt ta'siri")}</label>
                      <input
                        type="range"
                        className="promptInfluence"
                        id="promptInfluence"
                        min="0"
                        max="1"
                        step="0.1"
                        value={promptInfluence}
                        onChange={handlePromptInfluenceChange}
                      />
                      <label htmlFor="audioDuration">{t("Audio davomiyligi (soniyalar)")}</label>
                      <input
                        type="number"
                        className="audioDuration"
                        id="audioDuration"
                        min="0.5"
                        max="22"
                        step="0.1"
                        value={audioDuration}
                        onChange={handleAudioDurationChange}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="tool">
              <div className="result">
                <div className="result-top">
                  <p>{t("Audio fayl")}</p>
                </div>
                <div className="result-bottom">
                <div className="audio-player">
										<button className="play-btn" onClick={handlePlayPause}>
											<img src={isPlaying ? pause : play} width="20" alt="Play/Pause" />
										</button>
										<div className="track-info">
											<div className="track-title">{t("Audio fayl")}</div>
											<div className="track-time">
												<span id="currentTime">{currentTime}</span> /
												<span id="duration">{duration}</span>
											</div>
										</div>
										{audioUrl && (
											<a className="download-btn" href={audioUrl} download={audioUrl}>
												<img src={download} width="24" alt="Download" />
											</a>
										)}
										<audio
											ref={audioRef}
											id="audio"
											src={audioUrl}
											onError={(e) => console.error('Audio error:', e)}
										></audio>
									</div>
                  {/* <audio ref={audioRef} id="audio" controls style={{ display: 'none' }}>
                    <source src={audioUrl} type="audio/mp3" />
                    {t("Sizning brauzeringiz audio elementini qo'llab-quvvatlamaydi.")}
                  </audio>
                  <div className="download-link">
                    <a href={audioUrl} download>
                      <img src={download} width="20" alt="Download" />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextToSound;
