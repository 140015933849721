import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../utils/i18n";
import ServicesNav from "./Service/pages/ServicesNav";

import hamburberMenu from "../imgs/burger-menu-svgrepo-com.svg";
import logo from "../imgs/logo.png";

import "../style.css";
import "../responsive/1024.css";
import "../responsive/768.css";
import "../responsive/425.css";

function Nav({ setIsAuthenticated }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  const OpenMenu = () => {
    document.querySelector(".hamburger").style.display = "flex";
  };

  const changeLanguage = (e) => {
    const selectedLanguage = e.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
  };

  const handleLogout = () => {
    // Clear the token from local storage
    localStorage.clear();

    // Update the authentication state
    setIsLoggedIn(false);

    // Navigate to the login page
    navigate('/');

    // Force a page reload to ensure all components reflect the logout state
    window.location.reload();  // <-- This forces a page refresh after navigation
  };


  useEffect(() => {
    setIsLoggedIn(!!localStorage.getItem('token'));
  }, [isLoggedIn]);

  return (
    <nav>
      <div className="left">
        <Link to="/">
          <img src={logo} alt="Logo rasmi" height={36} />
        </Link>
      </div>
      <div className="center">
        <ul>
          <li>
            <Link to="/">{t("Home")}</Link>
          </li>
          <li>
            <Link to="/about">{t("About")}</Link>
          </li>
          <li>
            <Link to="/tts">{t("Services")}</Link>
          </li>
          <li>
            <Link to="/pricing">{t("Pricing")}</Link>
          </li>
        </ul>
      </div>
      <div className="right">
        <select
          name=""
          id="language-select"
          onChange={changeLanguage}
          value={i18n.language}
        >
          <option value="uz">Uzb</option>
          <option value="ru">Rus</option>
          <option value="en">Eng</option>
        </select>
        {isLoggedIn ? (
          <button onClick={handleLogout} className="logout">
            {t("Logout")}
          </button>

        ) : (
          <>
            <Link to="/login" className="login">
              {t("Log in")}
            </Link>
            <Link to="/signup" className="signup">
              {t("Sign Up")}
            </Link>
          </>
        )}
        <img
          src={hamburberMenu}
          onClick={OpenMenu}
          className="menu"
          width="25"
          alt=""
        />
      </div>
    </nav>
  );
}

export default Nav;