import React, { useState, useEffect, useRef } from 'react';
import "../styles/ProfileCards.css";
import person1 from '../imgs/person1.avif';
import person2 from '../imgs/person2.avif';
import person3 from '../imgs/person3.avif';
import person4 from '../imgs/person4.avif';
import person5 from '../imgs/person5.avif';
import person6 from '../imgs/person6.avif';

import audio1 from '../audios/Sarah.mp3';
import audio2 from '../audios/Laura.mp3';
import audio3 from '../audios/Charlie.mp3';
import audio4 from '../audios/Charlotte.mp3';
import audio5 from '../audios/george.mp3';
import audio6 from '../audios/Callum.mp3';
import playIcon from '../imgs/play.svg';
import pauseIcon from '../imgs/pause.svg';

const profiles = [
    { name: "Sarah", gender: "English Female", img: person1, bgColor: "#F97316", audio: audio1 },
    { name: "Laura", gender: "English Female", img: person2, bgColor: "#D1FAE5", audio: audio2 },
    { name: "Charlie", gender: "English Male", img: person3, bgColor: "#FB7185", audio: audio3 },
    { name: "Charlotte", gender: "English Female", img: person4, bgColor: "#FBBF24", audio: audio4 },
    { name: "George", gender: "English Male", img: person5, bgColor: "#93C5FD", audio: audio5 },
    { name: "Callum", gender: "English Male", img: person6, bgColor: "#C084FC", audio: audio6 }
];

const ProfileCards = () => {
    const [playingIndex, setPlayingIndex] = useState(null);
    const audioRefs = useRef([]);

    useEffect(() => {
        // Pause all audios when component unmounts
        return () => {
            audioRefs.current.forEach((audio) => {
                if (audio) audio.pause();
            });
        };
    }, []);

    useEffect(() => {
        // Add ended event listener to each audio element
        audioRefs.current.forEach((audio, index) => {
            if (audio) {
                audio.addEventListener('ended', () => handleAudioEnd(index));
            }
        });

        // Cleanup function to remove event listeners
        return () => {
            audioRefs.current.forEach((audio, index) => {
                if (audio) {
                    audio.removeEventListener('ended', () => handleAudioEnd(index));
                }
            });
        };
    }, []);

    const handleAudioEnd = (index) => {
        setPlayingIndex(null);
    };

    const handlePlayPause = (index) => {
        const currentAudio = audioRefs.current[index];
        if (playingIndex !== null && playingIndex !== index) {
            // Pause the currently playing audio if it's different
            audioRefs.current[playingIndex].pause();
            audioRefs.current[playingIndex].currentTime = 0; // Reset to start position
        }
        if (playingIndex === index && !currentAudio.paused) {
            currentAudio.pause();
            setPlayingIndex(null);
        } else {
            currentAudio.play().catch((error) => console.error("Audio play error:", error));
            setPlayingIndex(index);
        }
    };

    return (
        <div className="profile-container">
            {profiles.map((profile, index) => (
                <div key={index} className="profile-card" style={{ backgroundColor: profile.bgColor }}>
                    <img src={profile.img} alt={profile.name} className="profile-image" />
                    <div className="profile-details">
                        <div>
                            <h3>{profile.name}</h3>
                            <p>{profile.gender}</p>
                        </div>
                        <div>
                            <button
                                className="profile-button"
                                onClick={() => handlePlayPause(index)}
                            >
                                <img
                                    src={playingIndex === index ? pauseIcon : playIcon}
                                    alt={playingIndex === index ? 'Pause' : 'Play'}
                                    className="play-pause-icon"
                                />
                            </button>
                        </div>
                    </div>
                    <audio ref={(el) => audioRefs.current[index] = el} src={profile.audio} />
                </div>
            ))}
        </div>
    );
};

export default ProfileCards;