import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import i18n from './utils/i18n';

import Wifi from "./components/Wifi.jsx";
import Nav from './components/navbar.js';
import Hamburger from "./components/hamburger.js";
import Footer from './components/footer.js';
import Bgwave from './components/bgwave.jsx';
import Preloader from './components/Preloader';

import Header from './components/header.js';
import Componies from './components/componies.js';
import About from './components/about.js';
import Services from './components/services.jsx';
import Advantages from './components/advantages.js';
import Guide from './components/guide.js';
import Sts from './components/sts.js';
import Tts from './components/tts.js';
import Stt from './components/stt.js';
import VoiceClone from './components/VoiceClone.js';
import AboutPage from './components/aboutpage.js';
import Team from './components/team.js';
import Prices from './components/prices.js';
import Card from './components/card.jsx';
import CustomServices from './components/Service/CustomServices.jsx';
import Login from './components/login.js';
import Signup from './components/signup.js';
import TextToSound from './components/texttosound.jsx';
import Menu from './components/Service/pages/menu.jsx';
import Notification from './components/Service/pages/Notification.jsx';
import Index from './components/Router/Index.jsx';

function Home() {
  return (
    <section>
      <Bgwave />
      <Header />
      <Componies />
      <About />
      <Services />
      <Advantages />
      <Guide />
    </section>
  );
}

function Aboutpage() {
  return (
    <section>
      <AboutPage />
      <Team />
    </section>
  );
}

function Pricing() {
  return <Prices />;
}

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const showNavAndFooter = location.pathname !== "/login" &&
    location.pathname !== "/signup" &&
    location.pathname !== "/services/text-to-speech" &&
    location.pathname !== "/services/text-to-sound" &&
    location.pathname !== "/services/speech-to-text" &&
    location.pathname !== "/services/cloning" &&
    location.pathname !== "/services/dubbing" &&
    location.pathname !== "/new";

  const [showNotification, setShowNotification] = useState(false);

  // Function to handle link click
  const handleLinkClick = () => {
    setShowNotification(true); // Show notification when link is clicked
  };

  // Function to handle closing the notification
  const handleCloseNotification = () => {
    setShowNotification(false); // Hide notification when close button is clicked
  };

  useEffect(() => {
    const handleLoad = () => setIsLoading(false);
    window.addEventListener("load", handleLoad);
    const loadTimeout = setTimeout(() => setIsLoading(false), 5000);
    return () => {
      window.removeEventListener("load", handleLoad);
      clearTimeout(loadTimeout);
    };
  }, []);

  useEffect(() => {
    const checkAuthStatus = () => {
      const token = localStorage.getItem('token');
      const googleUser = localStorage.getItem('user');
      if (token || googleUser) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    checkAuthStatus();
    window.addEventListener('storage', checkAuthStatus);

    return () => {
      window.removeEventListener('storage', checkAuthStatus);
    };
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <div className="App">
      {showNavAndFooter && <Hamburger />}
      {showNavAndFooter && <Nav />}
      <Wifi />
      {showNotification && (
        <Notification onClose={handleCloseNotification} />
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/new" element={<Index />} />
        <Route path="/about" element={<Aboutpage />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/card" element={<Card />} />
        <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/signup" element={<Signup setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/sts" element={isAuthenticated ? <CustomServices /> : <Sts />} />
        <Route path="/tts" element={isAuthenticated ? <CustomServices /> : <Tts onLinkClick={handleLinkClick} />} />
        <Route path="/text-to-sound" element={isAuthenticated ? <CustomServices /> : <TextToSound onLinkClick={handleLinkClick} />} />
        <Route path="/stt" element={isAuthenticated ? <CustomServices /> : <Stt />} />
        <Route path="/voice-clone" element={isAuthenticated ? <CustomServices /> : <VoiceClone />} />
        <Route
          path="/services/*"
          element={isAuthenticated ? <CustomServices /> : <Navigate to="/login" replace />}
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {showNavAndFooter && <Footer />}
    </div>
  );
}

export default App;