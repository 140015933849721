import "./about.css"

import wave from "../../imgs/aboutwave.png"
function About() {
    return (
        <div className="about-zabon">
            <div className="container">
                <div className="top">
                    <h1>Zabon AI haqida</h1>
                </div>
                <div className="bottom">
                    <div className="left">
                        <img src={wave} alt="" />
                    </div>
                    <div className="right">
                        <p>Zabon.ai bu ultra realistik ovozli chatbot.U bir vaqtning o’zida bir nechta
                            ovoz va matnlarni qayta ishlay oladi. <br /><br />

                            Bundan tashqari aniq va samarali  konvertatsiyalarni ta’minlaydi,
                            yuqori sifatli audio fayllarni taqdim qiladi.
                            Bu loyiha nafaqat real nutqni yaratadi. Ushbu loyihaning boshida kuchli
                            ovozli sun'iy intellekt yotadi, u nafaqat inson nutqini taqlid qilishga,
                            balki murakkab so'rovlarni tushunish va ularga noziklik va
                            ravonlik bilan javob berishga qodir.</p>

                            <a href="">Bepul sinash</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;